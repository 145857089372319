
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.p-10 {
  padding: 10px;
}
.p-5 {
  padding: 5px;
}
button{

  border-radius: 5px ;
  padding:7px 10px
}
td{

  padding:5px 10px
}
th { text-align: left !important;}
* {box-sizing: border-box;}
.app {
  max-width: 100vw;
  .page-header {
    .menu_btn {
    display:none; background-color: transparent;
    color:#282c34; font-size: 22px;
    padding-top: 10px;
    }
    display: flex;
    text-align: center;
    background-color: #fff;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    position: fixed;
    width: 100vw;
    z-index: 1000;
    left: 0; right:0;
    padding-left: 270px;

    h4 {
    }
  }
  .app-sidebar {
    // top: 50px;
    /* Let's get this party started */
  

    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar:horizontal {
      height: 5px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
      -webkit-border-radius: 8px;
      border-radius: 8px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 8px;
      border-radius: 8px;
      background: rgb(209, 209, 209);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }

    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 1001;
    background: #fff;
    height: 100vh;
    overflow-y: auto;
   
    .app-sidebar-header {
      padding: 18px;
      font-size: 16px;
      height: 56px;
      background: rgb(77,76,172);
      color: #fff;
      white-space: nowrap;
      overflow: hidden;
      display: flex;
      align-items: center;justify-content: space-between;
      width:100% ;
      .close_menu_btn{
        display:none 
      }
    }
  }

  .hole-container {
    background-color: #eee;
    min-height: 100vh;
    max-width: 100vw;
    // padding-top: 56px;
  }
  .app-content {
    max-width: 100vw;

    padding: 5px;
    padding-top: 60px;
    &.logged {
      padding-left: 270px;

    }
  }
}
a.rs-sidenav-item-active { 

}

a.rs-sidenav-item {
  display: flex  !important; align-items: center; justify-content: flex-start;
  padding:8px 10px  !important;
  font-size: 18px !important;     color: #232323 !important;
.rs-sidenav-item-icon {
  background: #fff;
  border:1px solid #f2f2f2;
                          border-radius: 50px;
                          width: 50px;
                          height: 50px;
                          display: inline-flex;
                          align-items: center;
                          justify-content: center;
                          font-size: 20px;
                          margin-right: 10px
}
  & > a {
    color: #232323 !important;
  }
  &.rs-sidenav-item-active {
    color:rgb(77,76,172) !important;

    a {
      color:rgb(77,76,172) !important
    }
    .rs-sidenav-item-icon{
      background: rgb(77,76,172,0.3);
      border:0 none}
  }
  
 
}
label {
  font-weight: 500 !important;
  margin: 8px 0 5px 0;
  display: inline-block;
  text-transform: capitalize;
}
#root > div > section > aside > nav > div > ul > div> button {
  padding-left: 20px;
}
#root > div > section > aside > nav > div > ul > div.rs-dropdown> ul {
  background-color: rgb(70,103,209,0.1); color:#232323;
   &> a {
  a{margin:0 10px}
}}
/* Let's get this party started */
::-webkit-scrollbar {
  width: 9px;
}
::-webkit-scrollbar:horizontal {
  height: 9px;
}
/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 8px;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 8px;
  border-radius: 8px;
  background: #aaa;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #aaa;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #334455;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// steps
.rs-steps-item {
  cursor: pointer;
}

.rs-picker-toggle {
  // &:not(:focus) {
    border-color: #5a5b5f !important;
  // }
}
input {
   border-color: #5a5b5f !important;
  border-radius: 5px ;
  padding:5px 10px 

}
.rs-picker-toggle-value {
  color: #ccc;
}
@media print {
  body * {
    visibility: hidden;
  }

  #printedt____, #printedt____ * {
    visibility: visible;
  }

  #printedt____ {
    position: absolute;
    left: 0;
    top: 0;
  }
}
@media only screen and (max-width:800px) {
  .page-header{
    padding-left: 10px !important;
    .menu_btn {
      display:inline-block !important ;
    ; background-color: transparent;
      color:#282c34; font-size: 22px;
      padding-top: 10px;
    }
    
  }
  .app-sidebar {
    .close_menu_btn{
      display:inline-block !important ;
      ; background-color: transparent;
        color:#ffffff; font-size: 24px;
        padding: 10px;
    }
    width:0vw !important;
    &.show {
      width:100vw !important;
    }

  }
  .app-content {
    padding: 10px ; 
    padding-top: 75px;
     &.logged {
      padding-left: 10px !important;

    }
  }
  
}
.button_icon {
  width:40px; height: 40px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #eee;
  color: #555;
}